import "./App.scss"
import { getAntTheme, getColor, getComponent } from "./config/ThemeVariable"
import { GlobalStyle } from "./config/global.style"
import { RootState } from "./store/store"
import { changeTheme } from "./pages/Accounts/SelectTheme/ThemeSlice"
import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useLayoutEffect } from "react"
import { ThemeProvider } from "styled-components"
import { ConfigProvider } from "antd"
import AppRoutes from "./components/routes"
import { setupAxiosResponseInterceptors } from "./services/api"

function App() {
  /* eslint-disable-next-line */
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const audience = import.meta.env?.VITE_AUTH0_AUDIENCE
  const theme = useSelector((state: RootState) => state.theme.theme as string)
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme as string
  )
  const location = useLocation()
  const unprotectedRoutes = ['/supported-devices']

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0()

  // This useLayoutEffect sets up Axios response interceptors to handle authentication.
  // It uses the getAccessTokenSilently function to retrieve a new access token if needed,
  // the audience to specify in the authorizationParams in getAccessTokenSilently function, and the logout function
  // to log the user out if the token cannot be refreshed.
  useLayoutEffect(() => {
    setupAxiosResponseInterceptors({ getAccessTokenSilently, audience, logout })
  }, [getAccessTokenSilently, audience, logout])

  function base64UrlDecode(base64Url: any) {
    // Add padding if needed and replace characters according to the JWT spec
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    // Decode base64 string
    const decoded = atob(base64)
    // Convert the decoded string to a Uint8Array
    const uint8Array = new Uint8Array(decoded.length)
    for (let i = 0; i < decoded.length; i += 1) {
      uint8Array[i] = decoded.charCodeAt(i)
    }
    return uint8Array
  }

  function decodeJwt(token: any) {
    const parts = token.split(".")
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format")
    }

    const header = JSON.parse(
      new TextDecoder().decode(base64UrlDecode(parts[0]))
    )
    const payload = JSON.parse(
      new TextDecoder().decode(base64UrlDecode(parts[1]))
    )

    return {
      header,
      payload
    }
  }

  const AuthHeader = async () => {
    try {
      const audience = import.meta.env?.VITE_AUTH0_AUDIENCE
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accessToken: any = await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: "read:current_user"
        }
      })
      const data = decodeJwt(accessToken)

      if (
        data?.payload?.["https://myonecloud.com/scope"] === "Reseller" ||
        data?.payload?.["https://myonecloud.com/scope"] === "Super User"
      ) {
        localStorage.setItem(
          "showTeams",
          data?.payload?.["https://myonecloud.com/scope"]
        )
      }
      localStorage.setItem("accessToken", accessToken)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === "Login required" && !isAuthenticated) {
        const params = new URLSearchParams(window.location.search)
        const error = params.get("error")

        if (error === "access_denied") {
          navigate(`/relogin${window.location.search}`)
        } else {
          loginWithRedirect()
        }
      } else if (
        error?.message === "We could not locate your OneCloud Account"
      ) {
        logout()
        localStorage.clear()
      } else {
        navigate(`/relogin${window.location.search}`)
      }
    }
  }

  useEffect(() => {
    if (unprotectedRoutes.includes(location.pathname)) return // Prevent unnecessary execution
    AuthHeader()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user?.sub, isAuthenticated])

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: light)")
      .addEventListener("change", (e) => {
        const themes = e.matches ? "default" : "darkmode"

        if (currentTheme === "SYSTEM_PREFRENCE") {
          dispatch(
            changeTheme({ theme: themes, currentTheme: "SYSTEM_PREFRENCE" })
          )
        }
      })
  }, [currentTheme, dispatch])

  return (
    <ThemeProvider theme={getColor(theme)}>
      <ConfigProvider
        theme={{
          token: getAntTheme(theme),
          inherit: false,
          components: getComponent(theme)
        }}
      >
        <GlobalStyle />
        <AppRoutes />
      </ConfigProvider>
    </ThemeProvider>
  )
}

export default App
