import { message } from "antd"
import axios from "axios"

// TypeScript type for interceptor setup props
type SetupAxiosInterceptorsProps = {
  getAccessTokenSilently: (options: {
    authorizationParams: {
      audience: string;
      scope: string;
    };
    cacheMode: "off";
  }) => Promise<string>;
  audience: string;
  logout: () => Promise<void>;
};

const baseURL = import.meta.env.VITE_API_SERVER_URL

// eslint-disable-next-line import/prefer-default-export
export const apiInstance = axios.create({
  baseURL
})

apiInstance.interceptors.request.use(
  // eslint-disable-next-line func-names
  async function (config) {
    const accessToken = localStorage.getItem("accessToken")
    config.headers.Authorization = accessToken
      ? `Bearer ${accessToken}`
      : undefined
    return config
  },
  // eslint-disable-next-line func-names
  function (error) {
    return Promise.reject(new Error(error))
  }
)

export const setupAxiosResponseInterceptors = ({
  getAccessTokenSilently,
  audience,
  logout
}: SetupAxiosInterceptorsProps) => {
  // Add a response interceptor
  apiInstance.interceptors.response.use(
    // eslint-disable-next-line func-names
    function (response) {
      return response.data
    },
    // eslint-disable-next-line func-names
    async function (error) {
      const errorMessage = error.response.data?.error || error.response.data?.description
      console.log("ERROR>RESSPONE.data", error.response)
      
      function handleCommonErrors(status: number, errorMessage: string) {
        if (status === 500 || status === 400) {
          if (typeof errorMessage === "string") {
            message.error(errorMessage || "Something Went Wrong")
          }
          return true
        }
        return false
      }

      function handleOtherErrors(data: any) {
        if (
          typeof data.message === "string" ||
          typeof data.error === "string"
        ) {
          message.error(data.message || data.error || "Something Went Wrong")
        } else {
          for (let i = 0; i < data?.message?.length; i++) {
            message.error(data.message[i])
          }
        }
      }

      if (handleCommonErrors(error.response.status, errorMessage)) {
        return error.response.data
      }

      // Handle 401 Unauthorized error
      if (error?.response?.status === 401 && (error.response.data.error === "Invalid token" || error.response.data.error === "Signature has expired")) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience,
              scope: "read:current_user"
            },
            cacheMode: "off"
          })

          // Update localStorage with new token
          localStorage.setItem("accessToken", accessToken)

          // Retry the original request with the new token
          const retryConfig = error.config
          retryConfig.headers.Authorization = `Bearer ${accessToken}`
          return apiInstance.request(retryConfig)
        } catch (tokenError: any) {
          message.error(error.response.data.error || "Session expired. Please log in again.")
          // Optionally handle logout
          logout()
        }
      }

      if (error.response.status === 409) {
        if (
          error.response.data.error != "Extension number is currently in use." && typeof errorMessage === 'string'
        ) {
          message.error(errorMessage || "Something Went Wrong")
        }
        return error.response
      }

      if (error.response.status === 504) {
        message.error(error.response.data.error || "Something Went Wrong")
        return error.response
      }

      if (error.response.status === 422) {
        if (typeof errorMessage === "string") {
          message.error = error.response.data.error || "Something Went Wrong"
        }
        return error.response
      }

      handleOtherErrors(error.response.data)
      return Promise.reject(new Error(error))
    }
  )
}
